<template>

  <v-col class="mb-5 pb-5">
    <v-row>
      <v-col cols="12">
        <iframe width="100%" height="700" src="https://lamonnaiepirate.net"></iframe>>
      </v-col>
    </v-row>
  </v-col>
  
  </template>
  
  <style></style>
  
  <script>
  
  //import axios from 'axios'
  //import router from '../router/router'
  //import core from '../plugins/core.js'
  
  //import Post from '@/components/Post.vue'
  
  export default {
    name: 'empty',
    components: {  },
    data: () => ({
      
      
    }),
    async mounted(){
      
    },
    methods: {
      
  
    },
    computed: {
      
      
    }
  }
  </script>
  